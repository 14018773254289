import { graphql, Link, useStaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import i18n, { Lang } from "../i18n";
import { linkResolver } from "../utils/link-resolver";

type FooterProps = {
  lang: Lang;
};

export default (props: FooterProps) => {
  const { lang } = props;

  const queryResult = useStaticQuery(frontpageQuery);

  const frontpage = queryResult.prismic.allFrontpages.edges
    .map((edge: any) => edge.node)
    .filter((frontpage: any) => frontpage._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const translations = i18n[lang];

  return (
    <footer className="bg-darkpinegreen text-white mt-8 pb-8 light-links">
      <div className="container mx-auto px-4">
        <div className="flex-none">
          <h2 className="text-white">{frontpage.name[0].text}</h2>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-1 pb-4">
            <h4 className="text-white">{translations.frontPage.address}</h4>
            <RichText render={frontpage.address} />
            <p>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${frontpage.location.latitude},${frontpage.location.longitude}&query_place_id=ChIJPz_aUxAlj0YRCWIhtFjpOhM`}
                target="_blank"
              >
                Kartalla
              </a>
            </p>
          </div>

          <div className="flex-1 pb-4">
            <h4 className="text-white">{translations.frontPage.phoneNumber}</h4>
            {frontpage.phonenumbers.map((phonenumber: any, index: number) => (
              <div key={`phonenumber-${index}`}>
                <p>{phonenumber.phonenumbername}:</p>
                <p>
                  <a href={`tel:${phonenumber.phonenumber}`}>
                    {phonenumber.phonenumber}
                  </a>
                </p>
              </div>
            ))}
          </div>

          <div className="flex-1 pb-4">
            <h4 className="text-white">{translations.frontPage.email}</h4>

            {frontpage.emails.map((email: any, index: number) => (
              <div key={`email-${index}`}>
                <p>{email.emailname}:</p>
                <p>
                  <a href={`mailto:${email.email}`}>{email.email}</a>
                </p>
              </div>
            ))}
          </div>

          <div className="flex-1 pb-4">
            <h4 className="text-white">{translations.frontPage.language}</h4>
            <ul>
              <li>
                <Link
                  to={linkResolver({
                    lang: "fi",
                    type: "frontpage",
                  })}
                >
                  {translations.languages.inFinnish}
                </Link>
              </li>
              <li>
                <Link
                  to={linkResolver({
                    lang: "en-gb",
                    type: "frontpage",
                  })}
                >
                  {translations.languages.inEnglish}
                </Link>
              </li>
              <li>
                <Link
                  to={linkResolver({
                    lang: "de-de",
                    type: "frontpage",
                  })}
                >
                  {translations.languages.inGerman}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

const frontpageQuery = graphql`
  {
    prismic {
      allFrontpages {
        edges {
          node {
            _meta {
              uid
              lang
            }
            name
            address
            location
            emails {
              email
              emailname
            }
            phonenumbers {
              phonenumber
              phonenumbername
            }
          }
        }
      }
    }
  }
`;
