import React, { FunctionComponent } from "react";
import { Lang } from "../i18n";
import Footer from "./Footer";
import Header from "./Header";
import SEO from "./SEO";

type LayoutProps = {
  title?: string;
  lang: Lang;
  children: any;
  isFrontPage?: boolean;
};

const Layout: FunctionComponent<LayoutProps> = ({
  title = "Toijalan tila",
  lang,
  children,
  isFrontPage = false,
}) => {
  return (
    <div className="min-h-full flex flex-col">
      <SEO title={title} lang={lang} />
      <Header lang={lang} />
      <div
        className={
          isFrontPage ? "" : "container mx-auto mt-16 min-h-screen px-4"
        }
      >
        {children}
      </div>
      <Footer lang={lang} />
    </div>
  );
};

export default Layout;
