const colorMap: { [key: string]: string } = {
  Aamurusko: "dawnred",
  Aurinko: "sunyellow",
  Niitty: "meadowgreen",
  Taivas: "skyblue",
  Havu: "darkpinegreen",
  Iltahämärä: "duskblack",
  Punainen: "brightred",
  Sinivihreä: "cyan",
  Syvänsininen: "deepblue",
  Valkoinen: "white",
  Vaaleanharmaa: "lightgray",
  Harmaa: "mediumgray",
  Tummanharmaa: "darkgray",
  Musta: "black",
};

/*
text-dawnred
text-sunyellow
text-meadowgreen
text-skyblue
text-darkpinegreen
text-duskblack
text-brightred
text-cyan
text-deepblue
text-white
text-lightgray
text-mediumgray
text-darkgray
text-black
 */
export function humanReadableColorToKey(name: string) {
  return colorMap[name];
}
