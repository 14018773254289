import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

const SEO = ({ description, lang, meta, keywords, title }) => {
  const queryResult = useStaticQuery(frontpageQuery);

  const frontpage = queryResult.prismic.allFrontpages.edges
    .map((edge) => edge.node)
    .filter((page) => page._meta.lang === lang)
    .slice(0, 1)
    .pop();

  if (!frontpage) return null;

  const metaTitle = title
    ? `${title} – ${RichText.asText(frontpage.name)}`
    : RichText.asText(frontpage.name);
  const metaDescription = description || RichText.asText(frontpage.description);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />
      <meta
        name="keywords"
        content={frontpage.keywords.map((value) => value.keyword).join(",")}
      />

      <meta name="og:type" content="website" />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:description" content={metaDescription} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const frontpageQuery = graphql`
  {
    prismic {
      allFrontpages {
        edges {
          node {
            _meta {
              uid
              lang
            }
            description
            name
            slogan
            keywords {
              keyword
            }
          }
        }
      }
    }
  }
`;
