import { graphql, Link, useStaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import { Lang } from "../i18n";
import { humanReadableColorToKey } from "../utils/color-mapper";
import { linkResolver } from "../utils/link-resolver";

const cabinQuery = graphql`
  {
    prismic {
      allFrontpages {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
            highlightcolor
          }
        }
      }
      allCabinss {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
          }
        }
      }
      allGallerys {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
          }
        }
      }
      allToijala_farms {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
          }
        }
      }
      allServicess {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
          }
        }
      }
      allHistorys {
        edges {
          node {
            _meta {
              lang
              uid
              type
            }
            name
          }
        }
      }
    }
  }
`;

type HeaderProps = {
  lang: Lang;
};

export default (props: HeaderProps) => {
  const { lang } = props;

  const queryResult = useStaticQuery(cabinQuery);

  const frontpage = queryResult.prismic.allFrontpages.edges
    .map((edge: any) => edge.node)
    .filter((frontpage: any) => frontpage._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const gallery = queryResult.prismic.allGallerys.edges
    .map((edge: any) => edge.node)
    .filter((gallery: any) => gallery._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const farm = queryResult.prismic.allToijala_farms.edges
    .map((edge: any) => edge.node)
    .filter((farm: any) => farm._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const services = queryResult.prismic.allServicess.edges
    .map((edge: any) => edge.node)
    .filter((services: any) => services._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const history = queryResult.prismic.allHistorys.edges
    .map((edge: any) => edge.node)
    .filter((history: any) => history._meta.lang === lang)
    .slice(0, 1)
    .pop();

  const cabins = queryResult.prismic.allCabinss.edges
    .map((edge: any) => edge.node)
    .filter((cabins: any) => cabins._meta.lang === lang)
    .slice(0, 1)
    .pop();

  return (
    <header className="fixed z-10 flex flex-row justify-center bg-white w-full shadow-2xl h-12">
      <div className="container flex flex-wrap items-center px-4">
        <Link
          className="no-underline text-black"
          to={linkResolver(frontpage._meta)}
        >
          <h1
            className={`flex-1 relative whitespace-no-wrap font-extrabold text-${humanReadableColorToKey(
              frontpage.highlightcolor
            )} my-0 leading-none text-large shadow-text-white font-theme bg-black rounded-full text-center`}
            style={{ width: ".75em", lineHeight: ".75em", top: "-3px" }}
          >
            {frontpage.name[0].text.substring(0, 1)}
          </h1>
        </Link>

        <nav className="flex-1 black-links">
          <ul className="flex justify-end my-0">
            {cabins && (
              <li className="mr-2" key={`cabins-${cabins._meta.uid}`}>
                <Link to={linkResolver(cabins._meta)}>
                  <h4 className="my-0">{RichText.asText(cabins.name)}</h4>
                </Link>
              </li>
            )}
            {gallery && (
              <li className="mr-2" key={`gallery-${gallery._meta.uid}`}>
                <Link to={linkResolver(gallery._meta)}>
                  <h4 className="my-0">{RichText.asText(gallery.name)}</h4>
                </Link>
              </li>
            )}
            {farm && (
              <li className="mr-2" key={`farm-${gallery._meta.uid}`}>
                <Link to={linkResolver(farm._meta)}>
                  <h4 className="my-0">{RichText.asText(farm.name)}</h4>
                </Link>
              </li>
            )}
            {history && (
              <li className="mr-2" key={`history-${gallery._meta.uid}`}>
                <Link to={linkResolver(history._meta)}>
                  <h4 className="my-0">{RichText.asText(history.name)}</h4>
                </Link>
              </li>
            )}
            {services && (
              <li className="mr-2" key={`services-${gallery._meta.uid}`}>
                <Link to={linkResolver(services._meta)}>
                  <h4 className="my-0">{RichText.asText(services.name)}</h4>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};
